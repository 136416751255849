export const PRIVILEGES = {
    NOTE_MAKEPAYMENT: "NOTE_MAKEPAYMENT",
    ADMIN_PANEL: "ADMIN_PANEL",
    VENDOR: "VENDOR",
    API_CALL: "API_CALL",
    VIEW_NOTES_FOR_SALE: "VIEW_NOTES_FOR_SALE",
    ADD_NOTE_FOR_SALE: "ADD_NOTE_FOR_SALE",
    ALLOW_COUNTER_OFFER: "ALLOW_COUNTER_OFFER",
    INVESTOR_PROFILE_CUSTOM: "INVESTOR_PROFILE_CUSTOM",
    INVESTOR_PROFILES_CUSTOM_ADMIN: "INVESTOR_PROFILES_CUSTOM_ADMIN",
    INVESTOR_PROFILE: "INVESTOR_PROFILE",
    SHOW_CONTINGENCY_IN_OFFER: "SHOW_CONTINGENCY_IN_OFFER",
    CANCEL_PURCHASE_CONTRACT: "CANCEL_PURCHASE_CONTRACT",
    SEE_COMPANIES_BY_USER_EMAIL: "SEE_COMPANIES_BY_USER_EMAIL",
    SEE_GROUP_COMPANIES_BY_USER_EMAIL: "SEE_GROUP_COMPANIES_BY_USER_EMAIL"
};
export const LOGOS = {
    logo: require("@/assets/img/noteunlimited_logo.png"),
    logo_2k: require("@/assets/img/noteunlimited_logo_2k.png"),
    logo_4k: require("@/assets/img/noteunlimited_logo_4k.png"),
    logo_8k: require("@/assets/img/noteunlimited_logo_8k.png"),
    logo_webp: require("@/assets/img/noteunlimited_logo1x.webp"),
    logo_webp_2k: require("@/assets/img/noteunlimited_logo2x.webp"),
    logo_webp_4k: require("@/assets/img/noteunlimited_logo3x.webp"),
    logo_webp_8k: require("@/assets/img/noteunlimited_logo4x.webp"),
};
export const MQ1X = "(max-width: 1919px)";
export const MQ2X = "(max-width: 3839px)";
export const MQ3X = "(max-width: 7679px)";
export const MQ4X = "(min-width: 7680px)";
